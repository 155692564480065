/* ----------------------------------------------- */
/* Robots */
/* ----------------------------------------------- */

@font-face {
  font-family: "OCRAEXT";
  src: local("OCRAEXT"), url(../assets/font/OCRAEXT.TTF) format("truetype");
}

.section-robotstory {
  background-image: -webkit-linear-gradient(
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    ),
    url(img/universe.png);
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(img/universe.png);
  background-size: cover;
  background-position: center;
  height: 100vh;
  background-attachment: fixed;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.robotstory-einstein-smile {
  background-image: url(../assets/img/einstein-smile.jpg);
}

.robotstory-einstein-think {
  background-image: url(../assets/img/einstein-think.jpg);
}

.robotstory-einstein-idea {
  background-image: url(../assets/img/einstein-idea.jpg);
}

.robotstory-einstein-work {
  background-image: url(../assets/img/einstein-work.jpg);
}

.robotstory-frame {
  background-size: cover;
  background-position: center;
  background-color: rgb(48, 37, 148);
  width: 1000px;
  height: 562px;
  border: 2px solid #e67e22;
  border-radius: 2%;
  color: rgb(255, 255, 255);
  font-family: "OCRAEXT";
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.robotstory-frame .question-category {
  color: rgb(103, 92, 162);
}

.storyNavIcon {
  font-size: 250%;
  font-weight: 200%;
}
.storyNavigation {
  padding: 0px;
}

.storyPrev {
  margin-top: 410px;
  background-color: rgba(50, 73, 205, 0.01);
  text-align: left;
  padding: 0px;
  color: red;
}

.storyNext {
  margin-top: 410px;
  background-color: rgba(50, 73, 205, 0.01);
  text-align: right;
  padding: 0px;
  color: red;
}

.storyBubble {
  margin-top: 390px;
  background-image: url(../assets/img/story-bubble.png);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 100% 100%;
  padding-top: 40px;
  width: 100%;
  height: 100px;
  color: black;
}

.storyBox {
  margin-top: 390px;
  background-image: url(../assets/img/story-box.png);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 100% 100%;
  padding-top: 40px;
  width: 100%;
  height: 100px;
  color: black;
}

/**********************/
/* Select Robot Page  */
/**********************/

.robotSelection {
  /*background-color: rgba(208, 209, 215, 0.92);*/
  background-color: rgba(0, 0, 0, 0.803);
  border: 2px solid #b5b2b0;
  border-radius: 10px;
}
.robotSelection img {
  width: 130px;
  height: auto;
}

.robotSelection img:hover {
  -webkit-transform: scale(1.35);
  -ms-transform: scale(1.35);
  transform: scale(1.35);
}

.robotSelectionSpacer {
  content: ".";
  height: 0;
  visibility: hidden;
}

/**********/
/* Name Robot Page */
/**********/

.robotDisplay {
  background-color: rgba(114, 114, 113, 0.221);
  border: 2px solid #575655;
  border-radius: 10px;
  height: 400px;
}
.robotDisplay img {
  width: auto;
  height: 280px;
}

.robotDisplay img:hover {
  -webkit-transform: scale(1.35);
  -ms-transform: scale(1.35);
  transform: scale(1.35);
}

.robotNameEntrySection {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  color: black;
}

.robotNameForm {
  padding-top: 80px;
  padding-left: 60px;
  padding-right: 60px;
}

.inputField {
  color: black;
  background: rgb(250, 250, 217);
}

.learnAndPlay {
  display: inline-block;
  padding-top: 20px;
  text-align: center;
}

/**********/
/* Robot Landing */
/**********/
.robotLandingParent {
  display: inline-block;
}

.robotLanding {
  height: 200px;
  width: 200px;
  background: rgb(210, 210, 249, 0.9);
  color: rgb(3, 3, 91);
  border-radius: 30px;
  text-align: center;
}
.robotLanding img {
  width: auto;
  height: 150px;
}

.robotLanding img:hover {
  -webkit-transform: scale(1.35);
  -ms-transform: scale(1.35);
  transform: scale(1.35);
}

.robot-letters {
  font-family: "OCRAEXT";
}

.robot-letters h1 {
  font-family: "OCRAEXT";
  color: #e67e22;
}

.robot-landing-earth {
  position: absolute;
  top: 370px;
  left: 450px;
  height: 350px;
  width: 350px;
}

.robot-landing-earth img {
  height: 350px;
  width: auto;
}

.robot-landing-ufo {
  position: absolute;
  top: 290px;
  left: 150px;
  height: 150px;
  width: 300px;
  transform: rotate(20deg);
}

.img1 {
  position: absolute;
  top: -30px;
  left: 50px;
  height: auto;
  width: 100px;
}
.img2 {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 150px;
  width: auto;
}
