.smilestein-header-icon {
  width: 150px;
  height: auto;
  padding: 0px;
  margin: 0px;
}

/* ----------------------------------------------- */
/* Exercise Landing */
/* ----------------------------------------------- */
.section-excercise-landing {
  background-image: -webkit-linear-gradient(
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    ),
    url(img/universe.png);
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(img/universe.png);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 100vh;
}

.exercise-landing-frame {
  background-image: -webkit-linear-gradient(
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url(img/star_background.jpg);
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(img/star_background.jpg);
  background-size: cover;
  background-position: center;
  height: 80%;
  margin-left: 20%;
  width: 60%;
  border: 2px solid #e67e22;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  text-align: center;
  margin-bottom: 20px;
  min-height: 550px;
  overflow: hidden;
  position: relative;
}

.robot-navigation-icons {
  width: 180px;
  height: 180px;
  margin-top: 30px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 50px;
}

/* ----------------------------------------------- */
/* Exercise Worlds */
/* ----------------------------------------------- */

.section-excercise-worlds {
  background-image: -webkit-linear-gradient(
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    ),
    url(img/universe.png);
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(img/universe.png);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding-top: 40px;
  overflow: auto;
  height: 100vh;
}

.subject-frame {
  background-image: -webkit-linear-gradient(
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url(img/game_page_background.jpg);
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(img/game_page_background.jpg);
  background-size: cover;
  background-position: center;
  height: 80%;
  margin-left: 15%;
  width: 70%;
  border: 2px solid #e67e22;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  text-align: center;
  min-height: 700px;
}

.exercise-filter {
  text-align: center;
}

.subject-scroll-frame {
  height: 80%;
  margin-left: 0%;
  width: 100%;
  color: rgb(255, 255, 255);
  text-align: center;
  overflow: auto;
  min-height: 600px;
}

.category-tile {
  background-image: -webkit-linear-gradient(
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.7)
    ),
    url(img/continent1.png);
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(img/continent1.png);
  background-size: cover;
  background-position: center;
  margin-left: 5%;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 90%;
  border: 2px solid #e67e22;
  border-radius: 15px;
  color: rgb(255, 255, 255);
  text-align: center;
}

.category-tile h2 {
  color: rgb(11, 11, 165);
  font-family: "OCRAEXT";
}

.category-tile-background1 {
  background-image: url(../assets/img/continent_block_asia.jpg);
}
.category-tile-background2 {
  background-image: url(../assets/img/continent_block_europe.jpg);
}
.category-tile-background3 {
  background-image: url(../assets/img/continent_block_south_america.jpg);
}
.category-tile-background4 {
  background-image: url(../assets/img/continent_block_antarctica.jpg);
}
.category-tile-background5 {
  background-image: url(../assets/img/continent_block_africa.jpg);
}
.category-tile-background6 {
  background-image: url(../assets/img/continent_block_oceania.jpg);
}
.category-tile-background7 {
  background-image: url(../assets/img/continent_block_north_america.jpg);
}

.subcategory-box {
  text-align: center;
  margin: 0 auto;
  position: relative;
}
/* World-Background2.jpg  */
.subcategory-tile {
  height: 250px;
  width: 300px;
  border: 2px solid #e67e22;
  border-radius: 10px;
  margin: 20px;
  padding-top: 20px;
  background-color: rgb(241, 203, 120);
  color: rgb(53, 88, 164);
  text-align: center;
  display: inline-flex;
  font-size: 120%;
  font-weight: 400;
  overflow: hidden;
}

.sci {
  text-align: center;
}

.sci img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100px;
  width: auto;
}

.subcategory-progress {
  width: 300px;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 30px;
  padding-right: 30px;
}

.subcategory-tile-background1 {
  background-image: url(../assets/img/Country1.png);
}
.subcategory-tile-background2 {
  background-image: url(../assets/img/Country2.png);
}
.subcategory-tile-background3 {
  background-image: url(../assets/img/Country3.png);
}
.subcategory-tile-background4 {
  background-image: url(../assets/img/Country4.png);
}
.subcategory-tile-background5 {
  background-image: url(../assets/img/Country5.png);
}
.subcategory-tile-background6 {
  background-image: url(../assets/img/Country6.png);
}
.subcategory-tile-background7 {
  background-image: url(../assets/img/Country7.png);
}
.subcategory-tile-background8 {
  background-image: url(../assets/img/Country8.png);
}
.subcategory-tile-background9 {
  background-image: url(../assets/img/Country9.png);
}

/* ----------------------------------------------- */
/* Exercise World --- ONE World */
/* ----------------------------------------------- */

.section-excercise-world {
  background-image: -webkit-linear-gradient(
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    ),
    url(img/continent1.png);
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(img/continent1.png);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 100vh;
}

.subcategory-frame {
  background-image: -webkit-linear-gradient(
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url(img/country2.png);
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(img/country2.png);
  background-size: cover;
  background-position: center;
  height: 80%;
  margin-left: 10%;
  width: 80%;
  border: 2px solid #e67e22;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  text-align: center;
  margin-bottom: 20px;
  min-height: 600px;
}

.subcategory-table {
  height: 80%;
  margin-top: 20px;
  margin-left: 15%;
  width: 70%;
  border: 2px solid #e67e22;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  text-align: center;
  margin-bottom: 20px;
  overflow: auto;
}
