/* ----------------------------------------------- */
/* Questions */
/* ----------------------------------------------- */

.section-question {
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(img/country2.png);
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(img/country2.png);
  background-size: cover;
  background-position: center;
  height: 100vh;
  background-attachment: fixed;
  padding-top: 40px
}

.question-frame{
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(img/city1.png);
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(img/city1.png);
  background-size: cover;
  background-position: center;
  height: 80%;
  width: 70%;
  border: 2px solid #e67e22;
  border-radius: 2%;
  margin-left: 15%;
  color: rgb(255, 255, 255);
  text-align: center;
  min-height:  550px;
}

.question-header{
  margin: 0;
}

.question-preferences {
  text-align: left;
}

.question-category {
  text-align: center;
  font-size: 150%;
}

.question-exit {
  text-align: right;
}

.question-right-nav {
  float: right;
  list-style: none;
}

.question-right-nav li {
  display: inline-block;
  vertical-align: top;
}

.question-navigation-icons {
  width: 30px;
  height: 30px;
  margin-top: 0px;
  margin-left: 5px;
  margin-right: 5px;
}

.question-points{
  line-height: 120%;
  margin-top: 3px;
  margin-right: 30px;
  vertical-align: top;
}

.question-what-to-do {
  margin-top: 40px;
  text-align: center;
  font-size: 150%;
}

.question-text {
  margin-top: 80px;
  text-align: center;
  font-size: 150%;
}

.question-buttons{
  display: inline-block;
  margin-top: 50px;
  text-align: center;
  font-size: 150%;
}

.question-button{
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(img/CrabTransparent.png);
  background-image: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(img/CrabTransparent.png);
  width: 120px;
  height: 120px;
  margin-left: 10px;
  margin-right: 10px;
  background-size: cover;
  background-position: center;
  float: left;
  padding-top: 50px;
}

.question-button-result{
  width: 40px;
  height: 40px;
  border-radius: 7px;
}

.question-drop-context{
  display: inline-block;
}

.question-drop-source {
  border: 2px solid #e67e22;
  border-radius: 2%;
  height: 140px;
  min-width:  200px;
  border-color: #e67e22;
  float: left;
}

.question-drop-target {
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(img/Basket.png);
  background-image: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(img/Basket.png);
  background-size: cover; 
  background-position: center;
  border: 2px solid;
  border-radius: 2%;
  height: 140px;
  min-width:  200px;
  border-color: #6c6b6a;
  float: left;
}




.question-progress-bar {
  text-align: center;
  margin-top: 80px;
  margin-bottom: 20px;
  width: 80%;
}



.question-progress {
  text-align: center;
  background-color: rgb(191, 190, 190);
  height: 19px;
  border-radius: 5px;
}

/*Vertical progress bar*/
 .vertical-bar-frame{
   position:absolute;
   left:80%;
   top:30%;
 }
.vertical-progress-bar {
  position: relative;
  height: 300px;
  width: 20px;
  top: 20px;
  border-radius: 0px;
  border-color: rgb(191, 190, 190);
  background: rgb(191, 190, 190);
}

.filler {
  position:absolute;
  bottom:0px;
  width:100%;
  background: #b5cc18;
  border-radius: inherit;
  transition: width .2s ease-in;
  font-size:10px;
}

.stars{
  position: relative;
  right:27px;
  height:30px; 
}

.lighthouse{
  left:60px;
  position:relative;
  top: 15px;
}
.lighthouse-image{
  position: absolute;
  height:320px;
  width:auto; 
  right:10px;
  top:30px;
}
/*End of vertical progress bar */

.section{
  background: green;
  width:100%;
}
.section-exercise-summary{
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(img/country2.png);
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(img/country2.png);
  background-size: cover;
  background-position: center;
  height: 100%;
  background-attachment: fixed;
  padding-top: 40px;
  padding-bottom: 40px;
}

.exercise-summary-frame{
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(img/city1.png);
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(img/city1.png);
  background-size: cover;
  background-position: center;
  min-height:  100vh;
  width: 80%;
  border: 2px solid #e67e22;
  border-radius: 7px;
  margin-top: 10px;
  margin-left: 10%;
}

.exercise-summary-frame h2 {
  color: rgb(255, 255, 255);
  font-size: 130%;
}