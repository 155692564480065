/*
Orange color: #e67e22

*/


/* ----------------------------------------------- */
/* BASIC SETUP */
/* ----------------------------------------------- */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    background-color: #fff;
    color: #555;
    font-family: 'Lato', 'Arial', sans-serif;
    font-weight: 300;
    font-size: 20px;
    text-rendering: optimizeLegibility;
    /*overflow-x: hidden;*/
}

.clearfix {zoom: 1;}
.clearfix:after {
    content: '.';
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
}

/* ----------------------------------------------- */
/* REUSABLE COMPONENTS */
/* ----------------------------------------------- */


.row {
    max-width: 1140px;
    margin: 0 auto;
}

section {
    padding: 80px 0;
}

.box {
    padding: 1%;
}

/* ----- HEADINGS ----- */
h1,
h2,
h3 {
    font-weight: 300;
    text-transform: uppercase;
}

h1 {
    margin-top: 0;
    margin-bottom: 20px;
    color: #fff;
    font-size: 240%;
    word-spacing: 4px;
    letter-spacing: 1px;
}

h2 {
    font-size: 180%;
    word-spacing: 2px;
    text-align: center;
    margin-bottom: 30px;
    letter-spacing: 1px;
}

h3 {
    font-size: 110%;
    margin-bottom: 15px;
}

h2:after {
    display: block;
    height: 2px;
    background-color: #e67e22;
    content: " ";
    width: 100px;
    margin: 0 auto;
    margin-top: 30px;
}

/* ----- PARAGRAPHS ----- */
.long-copy {
    line-height: 145%;
    width: 70%;
    margin-left: 15%;
}

.box p {
    font-size: 90%;
    line-height: 145%;
}


/* ----- ICONS ----- */
.icon-big {
    font-size: 350%;
    display: block;
    color: #e67e22;
    margin-bottom: 10px;
}

.icon-small {
    display: inline-block;
    width: 30px;
    text-align: center;
    color: #e67e22;
    font-size: 120%;
    margin-right: 10px;
    
    /*secrets to align text and icons*/
    line-height: 120%;
    vertical-align: middle;
    margin-top: -5px;
}

.icon-game-dark {
    display: inline-block;
    width: 60px;
    text-align: center;
    color: #464444;
    font-size: 250%;
    font-weight: 300;
    margin-right: 10px;
    
    /*secrets to align text and icons*/
    line-height: 150%;
    vertical-align: middle;
    margin-top: -5px;
}

.icon-game-light {
    display: inline-block;
    width: 60px;
    text-align: center;
    color: #d0cccc;
    font-size: 250%;
    font-weight: 300;
    margin-right: 5px;
    margin-left: 5px;

    /*secrets to align text and icons*/
    line-height: 150%;
    vertical-align: middle;
    margin-top: -5px;
}

.icon-white {
    display: inline-block;
    width: 30px;
    text-align: center;
    color: #ffffff;
    font-size: 120%;
    margin-right: 10px;
    /*secrets to align text and icons*/
    line-height: 120%;
    vertical-align: middle;
    margin-top: -5px;
}



/* ----- LINKS ----- */

a:link,
a:visited {
    color: #e67e22;
    text-decoration: none;
    padding-bottom: 1px;
    border-bottom: 1px solid #e67e22;
    -webkit-transition: border-bottom 0.2s, color 0.2s;
    transition: border-bottom 0.2s, color 0.2s;
}

a:hover,
a:active {
    color: #555;
    border-bottom: 1px solid transparent;
}

/* ----- BUTTONS ----- */

a.btn,
.btn a,
input[type=submit] {
    display: inline-block;
    padding: 10px 30px;
    font-weight: 300;
    text-decoration: none;
    border-radius: 200px;
    -webkit-transition: background-color 0.2s, border 0.2s, color 0.2s;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
}
.btn a{
    float:left;
}

a.btn{
    display:inline-block;
}

a.btn-full,
.btn-full a,
input[type=submit] {
    background-color: #e67e22;
    border: 1px solid #e67e22;
    color: #fff;
    margin-right: 15px;
}
a.btn-ghost,
.btn-ghost a{
    border: 1px solid #e67e22;
    color: #e67e22;
}

a.btn:hover,
.btn a:hover,
input[type=submit]:hover,
input[type=submit]:active {
    background-color: #cf6d17;
}

a.btn-full:hover,
.btn-full a:hover,
.btn-full a:active {
    border: 1px solid #cf6d17;
}

a.btn-ghost:hover,
.btn-ghost a:hover,
.btn-ghost a:active {
    border: 1px solid #cf6d17;
    color: #fff;
}


