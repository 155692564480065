/* ----------------------------------------------- */
/* HOME PAGE COMPONENTS                            */
/* ----------------------------------------------- */


/* ----------------------------------------------- */
/* HEADER */
/* ----------------------------------------------- */

header {
    background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(img/stock-classroom.jpg);
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(img/stock-classroom.jpg);
    background-size: cover;
    background-position: center;
    height: 100vh;
    background-attachment: fixed;
}

.hero-text-box {
    position: absolute;
    width: 1140px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.logo {
    height: 70px;
    width: auto;
    float: left;
    margin-top: 30px;
}

.logo.small{
    margin-top: 0px;
}

.logo-black { 
    display: none;
    height: 50px;
    width: auto;
    float: left;
    margin: 5px 0;
}

/* Main navi */
.main-nav {
    float: right;
    list-style: none;
    margin-top: 55px;
}

.main-nav li {
    display: inline-block;
    margin-left: 40px;
}

.main-nav.sticky-header li a{
    color: #000;
}

.sticky-header li,
.main-nav li a {
    padding: 8px 0;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 90%;
    border-bottom: 2px solid transparent;
    -webkit-transition: border-bottom 0.2s;
    transition: border-bottom 0.2s;
}

.main-nav li a:hover,
.main-nav li a:active {
    border-bottom: 2px solid #e67e22;
}


/* Mobile navi */
.mobile-nav-icon {
    float: right;
    margin-top: 30px;
    cursor: pointer;
    display: none;
}

.mobile-nav-icon i {
    font-size: 200%;
    color: #fff;
}


/* Sticky navi */
.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.98);
    box-shadow: 0 2px 2px #efefef;
    z-index: 9999;
}

.sticky .main-nav { margin-top: 15px; }

.sticky .main-nav li a:link,
.sticky .main-nav li a:visited {
    padding: 16px 0;
    color: #555;
}

/*.sticky .logo { display: none; }
.sticky .logo-black { display: block; }*/

/* ----------------------------------------------- */
/* FEATURES */
/* ----------------------------------------------- */

.section-features .long-copy {
    margin-bottom: 30px;
}


/* ----------------------------------------------- */
/* MEALS */
/* ----------------------------------------------- */

.section-meals {
    padding: 0;
}

.meals-showcase {
    list-style: none;
    width: 100%;
}

.meals-showcase li {
    display: block;
    float: left;
    width: 25%;
}

.meal-photo {
    width: 100%;
    margin: 0;
    overflow: hidden;
    background-color: #000;
}

.meal-photo img {
    opacity: 0.7;
    width: 100%;
    height: auto;
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    transform: scale(1.15);
    -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
    transition: transform 0.5s, opacity 0.5s;
}

.meal-photo img:hover {
    opacity: 1;
    -webkit-transform: scale(1.03);
    -ms-transform: scale(1.03);
    transform: scale(1.03);
}



/* ----------------------------------------------- */
/* HOW IT WORKS SECTION */
/* ----------------------------------------------- */

.section-steps {
    background-color: #f4f4f4;
}

.steps-box {
    margin-top: 30px;
}


.steps-box:first-child {
    text-align: right;
    padding-right: 3%;
}

.steps-box:last-child {
    text-align: left;
    padding-left: 3%;
    margin-top: 70px;
}

.app-screen {
    width: 40%;
}

.works-step {
    margin-bottom: 50px;
    float: left;
}

.works-step:last-of-type {
    margin-bottom: 80px;
    float: left;
}

.works-step div {
    color: #e67e22;
    font-size: 140%;
    height: 54px;
    width: 54px;
    border: 2px solid #e67e22;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    margin-right: 25px;
    float: left;
    padding: 5px;
}
.works-step p {
    color: #777;
}

.btn-app:link,
.btn-app:visited {
    border: 0;
}

.btn-app img {
    height: 50px;
    width: auto;
    margin-right: 10px;
}


/* ----------------------------------------------- */
/* CITIES */
/* ----------------------------------------------- */

.box img {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
}

.city-feature {
    margin-bottom: 5px;
}


/* ----------------------------------------------- */
/* TESTIMONIALS */
/* ----------------------------------------------- */

.section-testimonials {
    background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(img/gamification1.png);
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(img/gamification1.png);
    background-size: cover;
    background-position: center;
    color: #fff;
    background-attachment: fixed;
}

blockquote {
    padding: 2%;
    font-style: italic;
    line-height: 145%;
    position: relative;
    margin-top: 40px;
}

blockquote:before {
    content: "\201C";
    font-size: 500%;
    display: block;
    position: absolute;
    top: -5px;
    left: -5px;
}

cite {
    font-size: 90%;
    margin-top: 25px;
    display: block;
}

cite img {
    height: 45px;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
}


/* ----------------------------------------------- */
/* SIGN UP */
/* ----------------------------------------------- */

.section-plans {
    background-color: #f4f4f4;
}

.plan-box {
    background-color: #fff;
    border-radius: 5px;
    width: 90%;
    margin-left: 5%;
    box-shadow: 0 2px 2px #efefef;
}

.plan-box div {
    padding: 15px;
    border-bottom: 1px solid #e8e8e8;
}

.plan-box div:first-child {
    background-color: #fcfcfc;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.plan-box div:last-child {
    text-align: center;
    border: 0;
}


.plan-price {
    font-size: 300%;
    margin-bottom: 10px;
    font-weight: 100;
    color: #e67e22;
}

.plan-price span {
    font-size: 30%;
    font-weight: 300;
}

.plan-price-meal {
    font-size: 80%;
}

.plan-box ul {
    list-style: none;
}

.plan-box ul li {
    padding: 5px 0;
}

/* ----------------------------------------------- */
/* TEAM */
/* ----------------------------------------------- */

.section-team {
    background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(img/uci-team-rocks.png);
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(img/uci-team-rocks.png);
    background-size: cover;
    background-position: center;
    color: #fff;
    background-attachment: fixed;
}

blockquote {
    padding: 2%;
    font-style: italic;
    line-height: 145%;
    position: relative;
    margin-top: 40px;
}

blockquote:before {
    content: "\201C";
    font-size: 500%;
    display: block;
    position: absolute;
    top: -5px;
    left: -5px;
}

cite {
    font-size: 90%;
    margin-top: 25px;
    display: block;
}

cite img {
    height: 45px;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
}



/* ----------------------------------------------- */
/* FORM */
/* ----------------------------------------------- */

.contact-form {
    width: 60%;
    margin: 0 auto;
}

input[type=text],
input[type=email],
select,
textarea {
    width: 100%;
    padding: 7px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

textarea {
    height: 100px;
}

input[type=checkbox] {
    margin: 10px 5px 10px 0;
}

*:focus {outline: none;}


/* ----------------------------------------------- */
/* FOOTER */
/* ----------------------------------------------- */

footer {
    background-color: #333;
    padding: 50px;
    font-size: 80%;
}

.footer-nav {
    list-style: none;
    float: left;
}

.social-links {
    list-style: none;
    float: right;
}

.footer-nav li,
.social-links li {
    display: inline-block;
    margin-right: 20px;
}

.footer-nav li:last-child,
.social-links li:last-child {
    margin-right: 0;
}

.footer-nav li a:link,
.footer-nav li a:visited, 
.social-links li a:link,
.social-links li a:visited {
    text-decoration: none;
    border: 0;
    color: #888;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
}

.footer-nav li a:hover,
.footer-nav li a:active {
    color: #ddd;
}

.social-links li a:link,
.social-links li a:visited {
    font-size: 160%;
}

.ion-social-facebook,
.ion-social-twitter,
.ion-social-googleplus,
.ion-social-instagram {
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
}

.ion-social-facebook:hover {
    color: #3b5998;
}

.ion-social-twitter:hover {
    color: #00aced;
}

.ion-social-googleplus:hover {
    color: #dd4b39;
}

.ion-social-instagram:hover {
    color: #517fa4;
}


footer p {
    color: #888;
    text-align: center;
    margin-top: 20px;
}

.privacy-header{
    margin-top:20px;
    margin-left:10%;
    margin-right:10%;
    font-size: 110%;
    font-weight: 400;
}
.privacy-paragraph{
    margin-top:15px;
    margin-left:10%;
    margin-right:10%;
    font-size: 100%;
}



